.login-card {
  .ant-card-body {
    padding: $largespace-xl $mediumspace-xl;
    @media (max-width: 1366px) {
      padding: $largespace-lg $mediumspace-lg;
    }
    @media (max-width: 767px) {
      padding: $largespace-xs $mediumspace-xs;
    }
  }
}

.country-card {
  .ant-card-body {
    padding: $largespace-lg $mediumspace-lg;
    @media (max-width: 767px) {
      padding: $largespace-xs $mediumspace-xs;
    }
  }
}

.ant-card-body {
  &:before,
  &:after {
    content: none;
  }
}

.uni-card {
  height: 100%;
  border-radius: 12px;
  transition: 0.2s;
  .ant-card-body {
    position: relative;
    padding: $base-size-xl;
    @media (max-width: 1366px) {
      padding: $mediumspace-lg;
    }
    @media (max-width: 767px) {
      padding: $mediumspace-xs;
    }
  }
}

.nopad {
  .ant-card-body {
    padding: 0 !important;
  }
}

.uni-card-tab {
  height: 100%;
  border-radius: 12px;
  overflow: hidden;

  >.ant-card-body {
    padding: 0;
  }
}

.uni-card.green-body{
  .ant-card-body {
    background-color: #02A574;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px 30px;
  }
}

.uni-card.bg-gray2{
  .ant-card-body {
    background-color: #202020;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px 30px;
  }
}

.inner-card {
  .ant-card-body {
    padding: 10px;
  }
}

.progress-card {
  background-color: #202020;
  height: 160px;
  .ant-card-body {
    padding: 10px;
  }

  .ant-typography {
    margin: -2em 0 0 0;
    text-align: center;
    color: #BEBEBE;
    font-size: 12px;
  }
}

.dashboard-calendar {
  height: 100%;
  .ant-card-body {
    padding: 5px;
    text-align: center;
    height: 100%;
  }
  .ant-badge-status-text {
    display: none;
  }

  &.activeCard  {
    background-color: #2C2C2C;
  }
}

.no-padding {
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
}

.side-panel {
  height: calc(100vh - 296px);

  .ant-card-body {
    height: 100%;
  }
}

.side-panel2 {
  height: calc(100vh - 330px);
}

.update-card {
  max-height: 400px;
  overflow-x: hidden;
}

.scrolling-card-ext {
  height: calc(100vh - 330px);
}

// .bottom-button {
//     position: absolute;
//     bottom:0;
//     left:0;
//     width: 100%;
//     .ant-card-body {
//         padding: $mediumspace-xl;
//     }
// }
.transparent-card {
  width: 100%;
  background-color: transparent;
  overflow-x: hidden;
  border-radius: 0;
  > .ant-card-body {
    padding: 0;
  }
}
.no-card-aform {
  width: 100%;
  background-color: transparent !important;
  margin-bottom: 10px;
  > .ant-card-body {
    padding: 0;
  }
}

.statistic-card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-left: 1px solid $menu-gray;
  background-color: transparent;
  overflow-x: hidden;
  > .ant-card-body {
    padding: 0 30px;
  }
}

.statistic-card-first {
  border: 0;
  > .ant-card-body {
    padding: 0 30px 0 0;
  }
}

.scrolling-card {
  width: 100%;
  background-color: transparent;
  overflow-x: hidden;
  height: calc(100vh - 296px);
  > .ant-card-body {
    padding: 0;
  }
}

.empty-card {
  background-color: transparent;
  height: 100%;
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.detail-cardtop {
  background: transparent;
  overflow-x: hidden;
  .ant-card-body {
    padding: 0;
  }
}

.onebtn-height {
  height: calc(100% - 40px);
}

.red-card {
  border-radius: 8px;
  background-color: $errors;
  .ant-card-body {
    padding: $smallspace-xl;
  }
  .op-6 {
    color: #fff;
  }
}

.pending-card {
  border-radius: 8px;
  background-color: $gold-color;
  .ant-card-body {
    padding: $smallspace-xl;
  }
  .op-6 {
    color: #fff;
  }
}

.inactive-card {
  border-radius: 8px;
  background-color: $menu-gray;
  .ant-card-body {
    padding: $smallspace-xl;
  }
  .op-6 {
    color: #fff;
  }
}

.border-card {
  margin-bottom: 20px;
  .ant-card-body {
    padding: $mediumspace-xl;
  }
}

.border-card2 {
  margin-bottom: 30px;
  .ant-card-body {
    padding: $smallspace-xl;
  }
}

.gray-card {
  background-color: transparent;
  .ant-collapse-item {
    background-color: #171717;
    border: 0;
    border-radius: 8px !important;
    padding: $smallspace-xl;
    margin-top: $mediumspace-xl;
    &:first-child {
      margin-top: 0;
    }
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 20px 0 0;
      }
    }
  }
}

.black-card {
  background-color: transparent;

  .ant-collapse-item {
    background-color: #0e0e0e;
    border: 0;
    border-radius: 8px !important;
    padding: $smallspace-xl;
    margin-top: $mediumspace-xl;

    &:first-child {
      margin-top: 0;
    }

    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 20px 0 0;
      }
    }
  }
}

.mini-card-blue {
  height: auto;
  border-radius: 4px;
  background-color: $lightblue;

  >.ant-card-body {
    padding: 20px 30px;
  }
}

.grey-card {
  background-color: transparent;
  &.darker {
    background-color: $black-shade;
    &.small {
      .ant-card-body {
          padding: $smallspace-xl;
        }
    }
  }
  &.light {
    background-color: $element-color;
    &.small {
      .ant-card-body {
        padding: $smallspace-xl;
      }
    }
  }
  .ant-collapse-item {
    background-color: #0e0e0e;
    border: 0;
    border-radius: 8px !important;
    padding: $smallspace-xl;
    margin-top: $mediumspace-xl;
    &:first-child {
      margin-top: 0;
    }
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 20px 0 0;
      }
    }
  }
}

.small-card12 {
  border-radius: 12px;
  .ant-card-body {
    padding: 20px;
  }
}

.small-card8 {
  border-radius: 8px;
  .ant-card-body {
    padding: 20px;
  }
}

.cardpx-0 {
  .ant-card-body {
    padding: 0 20px;
  }
}

.mini-card {
  border-radius: 8px;
  .ant-card-body {
    padding: 15px;
  }
}

.mini-card2 {
  border-radius: 8px;

  .ant-card-body {
    padding: 12px;
  }
}

.mini-card10 {
  .ant-card-body {
    padding: 10px 15px;
  }
}

.red-card-collapse {
  background-color: transparent;
  .ant-collapse-item {
    border: 0;
    border-radius: 0 !important;
    margin-top: $mediumspace-xl;
    &:first-child {
      margin-top: 0;
    }
    .ant-collapse-header {
      padding: $smallspace-xl;
      border-radius: 8px !important;
      background-color: $errors;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 20px 0 0;
      }
    }
  }
}

.cardouline-btn {
  width: 100%;
  padding: 27px;
  height: auto;
  border-radius: 12px;
  .anticon {
    font-size: 20px;
    display: block;
    margin-bottom: 12px;
  }
  span {
    display: block;
    line-height: 20px;
    margin-left: 0 !important;
  }
}

.download-link {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  background-color: $menu-gray;
  cursor: default;
  .ant-card-body {
    display: flex;
    justify-content: space-between;
    padding: 7.75px 15px;
    &:before,
    &:after {
      content: none;
    }
    .anticon {
      font-size: 20px;
    }
    .d-name {
      line-height: 20px;
      overflow: hidden;
      width: calc(100% - 40px);
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $based-color;
    }
  }
  &:hover {
    background-color: $element-color;
    .d-name {
      color: #fff;
    }
  }
}

.req-card {
  background-color: $black-shade;
  .ant-card-body {
    padding: 15px;
  }
}

.req-card-pending {
  background-color: $errors;
  .ant-card-body {
    padding: 15px;
  }
}

.smallcard-wicon {
  width: 130px;
  height: 40px;
  > .ant-card-body {
    padding: 10px;
  }
  .anticon {
    line-height: 20px;
    font-size: 20px;
  }
}

.uni-card-small {
  height: 100%;
  border-radius: 8px;
  > .ant-card-body {
    padding: 20px;
  }
  .anticon {
    font-size: 20px;
  }
  &.light {
    background-color: $element-color;
    &:hover {
      background-color: $green-color;
      color: #fff;
    }
  }
}

.uni-card-small-active {
  height: 100%;
  border-radius: 8px;
  background-color: $blue;
  color: #fff;
  > .ant-card-body {
    padding: 20px;
  }
  .anticon {
    font-size: 20px;
  }
}

.figures-card {
  transition: 0.2s;
  .ant-card-body {
    padding: $smallspace-xl;
  }
}

.card-hover {
  &:hover {
    background-color: #ce6664;
  }
}

.main-card-hover {
  &:hover {
    background-color: $element-color;
  }
}

.category-card {
  height: 100%;
  background-color: $element-color;
  cursor: pointer;
  &:hover {
    background-color: #303030;
  }
}

.field-card {
  height: 100%;
  background-color: $element-color;
  .ant-card-body {
    padding: 15px 20px;
  }
  // .customSelect {
  //   > div {
  //     background-color: $menu-gray;
  //     .css-1uccc91-singleValue {
  //       color: #fff;
  //     }
  //     .css-1hb7zxy-IndicatorsContainer > div {
  //       color: #fff;
  //     }
  //   }
  // }
}

.status-card {
  .ant-card-body {
    color: #fff;
    padding: 20px;
  }
}

.dashboard-card {
  height: 580px;
}

.point-cursor {
  cursor: pointer;
}

.no-listspace {
  height: 504px;
}

.landscape-card {
  height: 100%;
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

.app-collapse {
  background-color: transparent;
  width: 100%;
  .ant-collapse-item {
    border: 0;
    > .ant-collapse-header {
      padding: 0 40px 30px 0;
    }
    > .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0 0 30px;
      }
    }
    &:last-child {
      > .ant-collapse-header {
        padding: 0 40px 0 0;
      }
      > .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 30px 0 0;
        }
      }
    }
    .ant-collapse-arrow svg {
      transform: rotate(90deg) !important;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-arrow svg {
      transform: rotate(-90deg) !important;
    }
  }
}

.doc-collapse {
  background-color: transparent;
  width: 100%;
  .ant-collapse-item {
    border: 0;
  }
  .ant-collapse-icon-position-end > 
  .ant-collapse-item > 
  .ant-collapse-header {
    padding: 12px 0;
    padding-right: 29px;
    .ant-collapse-arrow {
      right: 10px;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
  }
}

.cardButton {
  border: 0;
  padding: 0;
  float: right;
  margin: -15px -15px 0px 0px;
  background-color: transparent !important;
  .ant-avatar {
    width: 30px;
    height: 30px;
    background-color: $container-color;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nospace-card {
  background: transparent !important;
  width: 100%;
  .ant-card-body {
    padding: 0;
  }
}

.cardinTab {
  .ant-card-body {
    padding: 15px 0 0 0;
  }
}

.pdf-view {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 10px;
    // border-radius: 15px;
  }
}

.time_ul {
  padding-bottom: 1em;
  border-left: 1px solid #7c7c7c;

  &:last-child {
    padding-bottom: 0;
  }
  .ant-col {
    //border-left: 1px solid #7c7c7c;
    border-bottom: 0 !important;
    padding: 0 0 25px 10px;
    position: relative;

    .ant-typography {
      line-height: 1;
    }

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #7c7c7c;
      position: absolute;
      left: -4px;
      top: 3px;
    }
    
    &:first-child {
      &:before {
        top: 0;
      }
    }

    &:last-child {
      padding-bottom: 0;

      &:before {
        top: 7px;
      }
    }
  }
}

.search_request {
  .ant-card-body .ant-row {
    flex-wrap: wrap;
  }
}

.transparent-card-wrap {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-x: hidden;
  > .ant-card-body {
    padding: 0;
    height: 100%;
  }
}

.no-radius {
  border-radius: 0 !important;
}

.card-icon {
  background-color: #000;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.white-card {
  color: black;
  background-color: white;
}

.mini-card-10 {
  .ant-card-body{
    padding: 10px;
  }
  &.gray-bg .ant-card-body{
    background-color: #202020;
    line-height: normal;
    .ant-typography {
      font-size: 11px;
    }
  }
}

.mini-card-5 {
  .ant-card-body{
    padding: 5px;
  }
}

.blue-card {
  background-color: #1F8CEB;
}

.bg-black {
  background: black;
}

.bg-gray1 {
  background-color: #171717 !important;
}

.bg-gray {
  background-color: #2C2C2C;
}

.bg-green {
  background-color: #02A574;
}

.topic-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333333FF' stroke-width='3' stroke-dasharray='10%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  background-color: transparent;
  position: relative;
  &:not(.active-white):hover,
  &.active {
    background: #02A574;
  }
  &.active-white {
    background: #EAEAEA;
  }
  &.add {
    cursor: pointer;
  }
}

.topic-button-group {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: 0.5s ease;
}

.answer-card {
  border-width: 2px;
  .ant-card-head {
    padding: 0 5px;
    background: black;
  }
  .ant-card-body {
    padding: 0;
    padding-top: 2px;
    border-radius: 5px;
    border: 1px solid;
  }
}

.quiz-setup {
  .ant-card-body {
    padding: 0;
    .ant-row {
      row-gap: 10px !important;
      .ant-table-cell {
        vertical-align: baseline !important;
      }
    }
  }
}

.details-card,
.review-card-bg {
  .ant-card-body {
    padding: $smallspace-xl !important;
  }
}

.dashed-card {
  text-align: center;
  height: 100%;
  background-color: transparent;
  border-style: dashed;
  border-width: 2px;
  .ant-card-body {
    line-height: 50px;
  }
  &:hover {
    cursor: pointer;
    background-color: #171717;
    border-style: none;
  }
}

.pdf-card {
  color: black;
  background-color: white;
  height: 100%;
  border-radius: 0;

  >.ant-card-body {
    padding: 90px;
  }

  .ant-typography {
    color: black;
  }

}

.transparent-card2 {
  width: 100%;
  background-color: transparent;
  overflow-x: hidden;
  border-radius: 0;

  >.ant-card-body {
    padding: 4px;
  }
}

.emptyspace {
  min-width: 50px;
  min-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.folio-card {
  border: 1px dashed $gray;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.green-card {
  border-radius: 8px;
  background-color: $green-color;
  .ant-card-body {
    padding: $smallspace-xl;
  }
  .op-6 {
    color: #fff;
  }
}

.ant-result {
  padding: 0 !important;
}

.brand-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !important;
  transition: 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6) !important;
  }
}

.shadow-card {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
}