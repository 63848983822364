.site-page-header {
  padding: 0;

  .ant-page-header-heading-left {
    margin: 0;
  }
  .ant-page-header-heading-extra {
    align-self: end;
  }
}

// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: rgba(0, 0, 0, 0.2);
// }

// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px $container-color;
//   border-radius: 0;
//   background: rgba(0, 0, 0, 0);
// }

.base-layout {
  padding: 0 0 0 20px;

  @media (min-width: 768px) {
    width: auto;
  }

  @media (min-width: 1100px) {
    padding: 0 40px;
  }

  @media (min-width: 1650px) {
    padding: 0 60px;
  }
}

.shrink-layout {
  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
}

.ant-layout-sider {
  background: transparent;

  .ant-card-body {
    padding: 30px;
  }
}

.flexibleRow {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(378px, 1fr));
}

.twocol-3070 {
  display: flex;

  @media (max-width: 1500px) {
    flex-wrap: wrap;
  }
}

.twocol-3070-responsive {
  @media (max-width: 1500px) {
    flex-wrap: nowrap;
  }
}

.w-240 {
  width: 240px
}

// .w-320 { width: 320px }
.w-295 {
  width: 295px
}

.twocol-3070-new {
  display: flex;

  @media (max-width: 1500px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.side-detail {
  width: 380px;
  max-width: 380px;
  min-width: 300px;
  padding-right: 20px;

  @media (min-width: 1920px) {
    min-width: 380px;
  }

  @media (max-width: 1500px) {
    width: 100%;
    min-width: unset;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

.side-detail-ext {
  width: 600px;
  max-width: 6000px;
}

.ignore-side-detail-responsive {
  @media (max-width: 1500px) {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    padding-right: 20px;
    padding-bottom: 0;
  }
}

.side-form {
  width: 780px;
  max-width: 100%;
  flex: 1 1 780px;

  @media (min-width: 1921px) {
    width: calc(100% - 380px);
  }

  @media (max-width: 1500px) {
    width: 100%;
    padding-bottom: 20px;
  }
}

.faculty_pop_table {
  .ant-modal-body {
    padding: 20px;
  }

  button {
    width: 200px;
  }
}