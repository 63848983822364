h1,h2,h3,h4,h5 {
    margin-top: 0;
}

.subtext-font {
    font-weight: normal;
    font-size: 17px;
}

.smallFont12 {
    font-size: 12px !important;
    line-height: 16px !important;
}

.smallFont14 {
    font-size: 14px;
    line-height: 22px;
}

.defaultFont {
    font-size: 15px !important;
    line-height: 22px !important;
}

.fontSize18Bold {
    font-size: 18px;
    font-weight: 700;
}

.fontSize18Medium {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.RegularFont {
    font-size: 16px !important;
}

.fontSize20 {
    font-size: 20px !important;
}

.ag-fontSize24 {
    font-size: 24px !important;
}

.ag-fontSize26 {
    font-size: 26px !important;
}

.ag-fontSize32 {
    font-size: 32px !important;
}
.fontSize40 {
    font-size: 40px !important;
}

.ag-fontSize30 {
    font-size: 30px !important;
    line-height: 38px;
    font-weight: 400;
}

.lineHeight20 {
    line-height: 20px !important;
}

.lineHeight40 {
    line-height: 40px !important;
}

.fontWeight600 {
    font-weight: 600;
}

.fwbold {
    font-weight: bold !important;
}

.fw200 {
    font-weight: 200 !important;
}

.Sentence {
    text-transform: capitalize !important;
}

h1.ant-typography,
.ant-typography h1 {
    font-weight: 400;
}

h2.ant-typography,
.ant-typography h2 {
    font-weight: 400;
}

h3.ant-typography,
.ant-typography h3 {
    font-weight: 400;
    margin-bottom: 30px;
}

h4.ant-typography,
.ant-typography h4 {
    font-weight: 400;
    margin-bottom: 20px;
}

h5.ant-typography,
.ant-typography h5 {
    font-weight: 400;
    margin-bottom: 20px;
}

.text-italic {
    font-style: italic;
}

.text-uppercase {
    text-transform: uppercase;
}

.SentanceCase {
    text-transform: capitalize;
}

.text-white {
    color: #fff !important;
}

.text-dark {
    color: #171717 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.fontSize24 {
    font-size: 24px !important;
}
.fontSize30 {
    font-size: 30px !important;
}
.link-cursor {
    cursor: pointer;
}

.ant-typography ul.ant-rate-disabled {
    margin: 0;
    li {
        margin: 0;
    }
}
.text-muted {
    color: #bfbfbf !important;
}
.caps {
    text-transform: uppercase;
}

.font-italic {
    font-style: italic;
}