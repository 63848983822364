// desktop

$largespace-xl: 50px;
$mediumspace-xl: 30px;
$smallspace-xl: 20px;
$base-size-xl: 15px;

// 1366
$largespace-lg: 32px;
$mediumspace-lg: 20px;
$smallspace-lg: 14px;

// mobile
$largespace-xs: 24px;
$mediumspace-xs: 12px;
$smallspace-xs: 8px;

