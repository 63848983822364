.sider-comment-list {
    width: 240px;
    // height: calc(100vh - 200px);
    overflow-x: hidden;
    .ant-comment-inner {
        padding: 15px 0;
    }
    .ant-comment-content {
        display: flex;
        flex-flow: column-reverse;
        &:hover {
            .linkSidebar {
                color: #fff;
            }
            .ant-comment-actions li {
                color: #fff;
            }
        }
    }
    .ant-comment-avatar {
        margin-right: 10px;
    }
    .ant-comment-avatar img {
        width: 45px;
        height: 45px;
        border: 1px solid $element-color;
        object-fit: cover;
    }
    .ant-comment-content-author {
        margin-bottom: 0;
    }
    .ant-comment-content-detail {
        line-height: 20px;
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 6px;
        color: $based-color;
    }
    .ant-tabs>.ant-tabs-nav,
    .ant-tabs>div>.ant-tabs-nav {
        margin: 0 0 15px 0;
    }
    .ant-comment-content-author-name,
    .ant-comment-content-author-time {
        color: $gray;
    }

    .ant-comment-actions {
        background-color: $menu-gray;
        padding: 12px;
        border-radius: 6px;
        margin: 0 0 10px 0;
        > li {
            color: $based-color;
            > span {
                font-size: 15px;
                font-weight: 300;
                
                color: $based-color;
                
            }
        }
    }

    .ant-list-items {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 20px;
            top: 0;
            width: 2px;
            height: 100%;
            background: $element-color;
        }

        li {
            position: relative;
            z-index: 9;
        }
    }

    .linkSidebar {
        color: $based-color;
        padding: 0;
        white-space: normal;
        text-align: left;
    }
}

.updation-list {
    max-height: 300px;
    overflow-x: hidden;
    .ant-list-item {
        justify-content: flex-start;
        padding: 15px 0;
    }
    .ant-list-item-meta {
        width: 42%;
        flex: none;
    }
    .ant-list-item-meta-title {
        margin-bottom: 5px;
        line-height: 20px;
        font-weight: 400;
    }
    .ant-list-item-meta-description {
        color: $gray;
        font-size: 12px;
        line-height: 16px;
    }
    .reply-box {
        background-color: $menu-gray;
        padding: 15px;
        font-weight: 300;
        line-height: 20px;
        border-radius: 8px;
        margin-bottom: 0;
        &::before {
            content: '';
            width: 30px;
            height: 10px;
        }
    }
    .comment-date {
        font-size: 12px;
        line-height: 16px;
        color: $gray;
        margin-bottom: 0;
    }
}