$blue: #0077B6;
$container-color: #171717;
$element-color: #202020;
$gray: #7C7C7C;
$based-color: #BEBEBE;
$green-color: #02A574;
$green-dark: #03865e;
$green-active: #016b4b;

$gold-color: #E89005;
$menu-gray: #2C2C2C;
$errors: #C3423F;
$errors-dark: #AB3836;
$errors-light: #CE6664;
$black-shade: #0E0E0E;

$white: #fff;
$lightblue: #dbeef5;
