.fullWidth-checbox {
    label {
        width: 100%;
        padding: 8.5px 15px;
        background: #202020;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
            color: $based-color;
            line-height: 20px;
            padding-left: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .ant-checkbox {
        top: 0;
    }
    .ant-checkbox-inner {
        border-radius: 4px;
    }
    .ant-checkbox-group-item {
        &::after {
            content: none;
        }
    }
}


.simple-checbox {
    label {
        width: 100%;
        padding: 0;
        background: #202020;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        justify-content: space-between;
        span {
            color: $based-color;
            line-height: 20px;
            padding-left: 0;
        }
    }
    &.wauto{
        label {
            flex-flow: inherit !important;
            background: transparent !important;
            justify-content: center !important;
        }
        span.ant-checkbox {
            background: #202020;
        }
        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
            background-color: #02A574 !important;
            border-color: #02A574 !important;
        }
    }
    .ant-checkbox {
        top: 0;
    }
    .ant-checkbox-inner {
        border-radius: 4px;
    }
    .ant-checkbox-group-item {
        &::after {
            content: none;
        }
    }
}

.simple-checbox.justify-left {
    label {
        justify-content: left;
    }
}

.one-checbox {
    .ant-checkbox-inner {
        border-radius: 4px;
    }
}

.checkbox-alone {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    background: #202020;
    .ant-checkbox-inner {
        border-radius: 4px;
    }
}

.graycheckbox {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
        border-color: $based-color;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-input:focus+.ant-checkbox-inner {
        border-color: $container-color;
        background-color: $container-color;
    }
    .ant-checkbox-checked::after {
        border-color: $container-color;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: $gray;
    }
}

.bluecheckbox {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
        border: 1px solid white;
        border-color: white;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-input:focus+.ant-checkbox-inner {
        border-color: #0077B6;
        background-color: #0077B6;
    }
    .ant-checkbox-checked::after {
        border-color: #0077B6;
    }
    .ant-checkbox-inner {
        border-radius: 4px;
    }
}

.labeldefaultFont {
    align-items: center;
    .ant-form-item-label > label {
        font-size: 15px;
        line-height: 24px;
    }
}

.gray-input {
    .ant-input, .ant-picker {
        background-color: $menu-gray
    }
}

.black-input {
    .ant-input, .ant-picker {
        background-color: $black-shade;
    }
}

.same-input {
    .ant-input, .ant-picker {
        background-color: $container-color;
    }
}

.select-withicon {
    .searchIcon {
        position: absolute;
        top: 8px;
        left: 20px;
        font-size: 20px;
        z-index: 1;
    }
    .ant-select-selector {
        .ant-select-selection-search,
        .ant-select-selection-placeholder,
        .ant-select-selection-item
        {
           padding-left: 30px;
        }
    }
}

.circle20 {
    width: 20px;
    height: 20px;
    background: $menu-gray;
    padding:4px;
    border-radius: 50%;
    .anticon {
        vertical-align: top;
    }
}

.ant-rate-star-first, .ant-rate-star-second {
    color: $menu-gray;
}

.ratingField {
    .ant-rate {
        display: flex;
        justify-content: space-between;
    }
    .ant-rate-star {
        margin:0;
    }
}
.green-rate {
    .ant-rate {
        color: $green-color;
    }
}
.blue-rate {
    .ant-rate {
        color: $blue;
    }
}

.no-arrow {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    &.green-bold {
        input {
            font-weight: 600;
            color: $green-color;
            font-size: 22px;
        }
    }
}


.check-list {
    label {
        padding: 9px 0 12px;
        background: transparent;
        border-radius: 0;
        display: flex;
        align-items: center;
        flex-flow: row;
        justify-content: flex-start;
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        span {
            padding-left: 8px;
        }
        &:last-child {
            border: 0;
        }
    }
}

.side-check {
    label {
        padding-left: 0;
        padding-right: 0;
        background: transparent;
    }
}
.transparentField {
    .ant-input {
        border: 0;
        background-color: transparent;
    }
}

.noborderback {
    .ant-input {
        padding-left: 0;
        background-color: transparent;
        border-radius: 0;
    }

    .ant-input:focus,
    .ant-input-focused {
        border: 0;
        box-shadow: none;
    }
}

.white-input {
    .ant-input-group-addon {
        background-color: #fff;
    }
    .ant-input {
        background-color: #fff;
        color: #202020;
    }
    .ant-picker {
        background-color: #fff;
        > input {
            color: #202020;
        }
    }
    .ant-picker-suffix {
        color:rgba(0, 0, 0, 0.3);
        &:hover {
            color:rgba(0, 0, 0, 0.45);
        }
    }
    .ant-picker-clear {
        color:rgba(0, 0, 0, 0.3);
        background-color: #fff;
        &:hover {
            color:rgba(0, 0, 0, 0.45);
        }
    }

}

.react-tel-input {
    .form-control {
        font-size: 15px !important;
        letter-spacing: normal !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        // background: $element-color !important;
        border: 1px solid transparent !important;
        border-radius: 8px !important;
        line-height: 1.5715 !important;
        height: auto !important;
        width: 100% !important;
        outline: none !important;
    }

    .flag-dropdown {
        background-color: transparent !important;
        border: 1px solid transparent !important;
        border-radius: 8px 0 0 8px !important;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: $blue !important;
        box-shadow: 0 0 0 2px rgba($blue, 0.2) !important;
        border-right-width: 1px !important;
        outline: 0 !important;
    }

    .selected-flag {
        border-radius: 8px 0 0 8px;

        // &:hover,
        // &:focus {
        //     background-color: $menu-gray !important;
        // }
    }

    .flag-dropdown {
        &.open {
            .selected-flag {
                // background-color: $menu-gray !important;
                border-radius: 8px 0 0 0 !important;
            }

            // background-color: $menu-gray !important;
            border-radius: 8px 0 0 0 !important;
        }
    }

    .country-list {
        // background-color: $container-color !important;
        border-radius: 0 0 8px 8px !important;

        .country {

            &:hover,
            &.highlight {
                background-color: $blue !important;
            }

            .dial-code {
                color: $based-color !important;
            }
        }
    }
}

.gray-radio {
    .ant-radio-inner {
        background-color: $element-color;
        border-color: $element-color;

        &:after {
            background-color: $gray;
        }
    }

    .ant-radio-checked {
        .ant-radio-inner {
            border-color: $menu-gray;
        }

        &:after {
            border-color: $gray;
        }
    }
}