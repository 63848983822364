.chat-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  z-index: 8;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;

  >.ant-card-body {
    height: 100%;
  }

  &.active {
    left: 100%;
  }
}

.ant-layout-sider-collapsed {
  .chat-window {
    display: none;
  }
}

.input-card {
  height: auto;
  width: 100%;
  border-radius: 0;
  background-color: $menu-gray;

  >.ant-card-body {
    padding: 15px 20px;
    height: auto;
  }
}

.chat-body {
  height: calc(100% - 242px);
  width: 100%;

  >.ant-card-body {
    padding: 0;
    height: 100%;
  }

  .scroller {
    height: 100%;
  }

  .scrol-pad {
    padding: 0 30px;
  }
}

.chat-header {
  height: auto;
  width: 100%;

  >.ant-card-body {
    height: auto;
  }
}

.message-box {
  max-width: 478px;
  margin-top: 30px;
  display: flex;
}

.message-tag {
  padding: 12px;
  background-color: $menu-gray;
  display: flex;
  justify-content: flex-end;
  border-radius: 12px;

  &.message_file {
    padding: 0;

    button {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #fff;
      }

      .anticon {
        font-size: 28px;
      }
    }
  }
}

.empty-space {
  width: 42px;
}

.incoming {
  margin-top: 5px;
  margin-left: auto;
  justify-content: flex-end;

  .message-tag {
    background-color: $blue;
    color: $white;
    text-align: right;
  }

  >.ant-row {
    flex-direction: row-reverse;
  }

  +.incoming {
    .ant-row {
      .ant-col {
        &:first-child {
          display: none;
        }

        &:last-child {
          padding-right: 56px !important;
        }
      }
    }
  }
}

.sameuser {
  margin-top: 5px;

  +.sameuser {
    .ant-row {
      .ant-col {
        &:first-child {
          display: none;
        }

        &:last-child {
          padding-left: 56px !important;
        }
      }
    }
  }
}

.chat-info {
  padding: 0.5em 0.75em;
  background-color: $blue;
  margin: 0.5em 0 0;
  display: inline-block;
  border-radius: 10px;
  font-size: 12px;
}

.other-chat-info {
  background-color: $gray;
}

.online {
  border: 1px solid $green-color;
}

.icon-text-wrap {
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat_btn {
  display: block;
  margin: 0;
  padding: 10px 15px;
  white-space: normal;
  background: #202020;
  border: 0;
  border-radius: 6px;
  height: auto;
  text-align: left;
  color: #fff;

  &:hover,
  &.active {
    background-color: #0077B6;
    color: #fff;
  }
}

.right-card-link {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  line-height: 1;
  text-align: left;
  height: auto;
  width: auto;
  color: $based-color;
  font-size: 20px;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

.upload_btn {
  border: 0;
  padding: 0;
  width: 100%;

  .anticon {
    font-size: 20px;
  }

  &:hover {
    color: #fff !important;
  }
}

.show_members {
  padding: 30px;
  overflow: auto;
  height: 100%;
}