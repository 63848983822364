.c-header {
    background-color: transparent;
    .ant-card-body {
        padding: 30px 0 60px 0;
    }
}

.custom-select {
    .ant-select-selection-placeholder {
        color: #fff;
    }
    .ant-select-selection-item {
        color: #fff;
    }
    &.ant-select-open {
        .ant-select-selection-item {
            color: #fff;
        }
    }
}

.ant-select-arrow {
    color: #fff;
}

// .search-input {
//     background-color: $container-color;
//     .ant-select-selection-placeholder {
//         color: $gray;
//     }
//     .ant-input {
//         background-color: $container-color;
//     }
//     .ant-input-prefix {
//         margin-right: 10px;
//     }
//     &.bordered {
//         border: 1px solid #333333;
//         &:hover {
//             border-color: #333333;    
//         }
//     }
// }
