.custom-tabs {
    overflow: unset;
    >.ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                width: 100%;
                padding: 5px;       
                background-color: #000;
                border-radius: 8px;
                .ant-tabs-tab {
                    width: 100%;
                    height: 40px;
                    color: $gray;
                    border-radius: 8px !important;
                    justify-content: center;
                    &:hover {
                        color: #fff;
                    }
                    &.ant-tabs-tab-active .ant-tabs-tab-btn {
                        color: #fff;
                    }
                }
            }
        }
    }
    
    > .ant-tabs-nav .ant-tabs-tab, > div > .ant-tabs-nav .ant-tabs-tab {
        border: 0;
    }
    > .ant-tabs-nav .ant-tabs-tab-active, > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: #fff;
        background-color: $blue;
    }
}

.custom-tabs2 {
    > .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                flex-wrap: wrap;
                .ant-tabs-tab {
                    width: 20%;
                }
            }
        }
    }
}

.vertical-tabs {

    >div>.ant-tabs-nav,
    >.ant-tabs-nav {
        height: calc(100vh - 297px);
        width: 360px;
        max-width: 360px;
        min-width: 280px;
        padding: 30px;
        border-radius: 12px;
        background: $container-color;

        &::before {
            content: normal;
        }

        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    text-align: left;
                    height: auto;
                    line-height: unset;
                    padding: 12px;
                    color: $based-color;
                    border-radius: 8px !important;
                    margin-right: 0;
                    border: 0;

                    &:hover {
                        color: $based-color;
                        background: $element-color;
                    }

                    &.ant-tabs-tab-active {
                        color: $based-color;
                        background: $element-color;

                        .ant-tabs-tab-btn {
                            color: $based-color;
                        }
                    }
                }
            }
        }

    }

    >.ant-tabs-content-holder,
    >div>.ant-tabs-content-holder {
        overflow-x: hidden;
        height: calc(100vh - 297px);
        margin-left: 0;
        border-left: 0;
    }

    .ant-tabs-extra-content {
        width: 100%;
    }
}

.gray-tabs {
    margin-top: 15px;
    > div >.ant-tabs-nav, >.ant-tabs-nav {
        &::before {
            content: normal;
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    border:0;
                    padding: 10px 15px;
                    height: 40px;
                    color: $gray;
                    border-radius: 8px !important;
                    justify-content: center;
                    margin-right: 15px;
                    &:hover {
                        color: $gray;
                        background-color: $element-color;
                    }
                    &.ant-tabs-tab-active {
                        color: #fff;
                        background-color: $menu-gray;
                        .ant-tabs-tab-btn {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.custom-gray-tabs {
    margin-top: 0;
    .ant-tabs-nav {
        padding: 20px 30px;
        margin: 0;
    }
}

.-space30 {
    .ant-tabs-nav {
        margin-bottom: $mediumspace-xl;
    }
}

.tab-badge {
    .ant-badge-count {
        padding: 0 15px;
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        background-color: $menu-gray;
        color: #fff;
    }
}

.menu-badge {
    .ant-badge-count {
        width: 20px;
        height: 20px;
        font-size: 15px;
        line-height: 20px;
        border-radius: 4px;
        background-color: $black-shade;
        color: #fff;
        padding:0;
    }
}

.tab-bold {
    position: relative;
    > .ant-tabs-nav {
        .ant-tabs-tab  {
            border: 0;
            font-size: 16px;
            font-weight: 400;
            .ant-tabs-tab-btn {
                color: $gray;
            }
            .tab-header {
                color: $gray;
                transition: 0.2s;
                font-size: 16px;
                font-weight: 400;
            }
            &:first-child {
                padding-left: 0;
            }
        }
        &:before {
            content: none;
        }
        .ant-tabs-tab-active {
            background-color: transparent;
            font-size: 26px;
            font-weight: 400;
            .ant-tabs-tab-btn {
                color: #fff;
            }
            .tab-header {
                color: #fff;
                font-size: 21px;
                font-weight: 400;
            }
            .tab-badge {
                .ant-badge-count {
                    background-color: $errors;
                }
            }
        }
    }
    > div > .ant-tabs-nav {
        .ant-tabs-tab {
            border: 0;
            font-size: 16px;
            font-weight: 400;
            .ant-tabs-tab-btn {
                color: $gray;
            }
            .tab-header {
                color: $gray;
                font-size: 16px;
                font-weight: 400;
            }
        }
        &:before {
            content: none;
        }
        .ant-tabs-tab-active {
            font-size: 26px;
            font-weight: 400;
            background-color: transparent;
            .ant-tabs-tab-btn {
                color: #fff;
            }
            .tab-header {
                color: #fff;
                font-size: 26px;
                font-weight: 400;
            }
        }
        
    } 
        
}

.optionsTabs {
    position: absolute;
    top:13px;
    right:0;
    .ant-radio-button-wrapper {
        border: 0;
        font-size: 16px;
        line-height: 2;
        background-color: $container-color;
    }
    .ant-radio-button-wrapper:first-child {
        border: 0;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
    }
}
.optionsTabsRes {
    position: relative;
    margin-bottom: 20px;
    top:0;
    justify-content: flex-end;
    width: 100%;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    padding: 7.5px 10px;
}

.hide-tablist .ant-tabs-nav {
    display: none;
}