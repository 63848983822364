@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
// (base)
@import './base/colors';
@import './base/spaces';
@import './base/typography';
// (layout)
@import './layout/header';
@import './layout/main';
@import './layout/masonry';
// (partials)
@import './partials/menu';
@import './partials/common';
@import './partials/cards';
@import './partials/reqCard';
@import './partials/comments';
@import './partials/tabs';
@import './partials/table';
@import './partials/tags';
@import './partials/modal';
@import './partials/forms';
@import './partials/steps';
@import './partials/calendar';
@import './partials/chats';
// (pages)
@import './pages/login';
@import './pages/marketing';
@import './pages/aqa';
@import './pages/registry';
@import './pages/profile';
@import './pages/leaves';


.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.user-name {
  font-weight: bold;
}